.experience{
    height:max-content;
    border-radius: 20px;
}
.experienceblock > div > h3 {
    font-size: 2rem;
}
.experienceblock{
    /* display: flex; */
}
.experienceawstext{
    font-weight: 'bold';
    color: #5E8C6A;
}
.experiencemyaws{
    background: rgba(255, 255, 255, 0.2);
    border-radius: 30px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin: 20px;
}
.left{
    flex: 1;
}
.left p{
    margin: 0;
    font-size: 1rem;
    
}
#experience-p p::before {
    /* content: "-"; */
    margin-right: 0.5rem; /* Adjust the spacing between the hyphen and text as needed */
}
/* .left > div p{
    border-style:groove;
} */
.right{
    flex: 1;
}
.left-aws{
    width: 100%;
    height: 500px;
    /* background: linear-gradient(45deg, #5E8C6A 0%, #BFB35A ); */
    display: flex;
    margin-bottom: 40px;
}
.left-tlbu{
    width: 100%;
    height: auto;
    /* padding-bottom: 20px; */
    /* background: linear-gradient(45deg, #5E8C6A 0%, #BFB35A ); */
    /* margin-top: 10px; */
}
.left-tlbu p{
    margin: 0;
    font-size: 1rem;
}
.text{
    /* font-family: "Sofia", sans-serif; */
    font-size: 2rem;
    /* transform: translateY(70px); */
    text-align: left;
    color: #5E8C6A;
    margin-left: 20px;
}
.subtitle{
    text-align: left;
    font-size: 1rem;
}


.sp{
    display: flex;
    flex-direction: column;
    height: 100vh;
}
.sp > h1{
    text-align: center;
    margin: 0;
    padding: 20px;
}
/* .sp-top{
    flex: 1;
}
.sp-bot {
  flex: 1;
} */

.sp-mid {
  flex: 1;
  --s: 140px; /* control the size */
  --c: #170409; /* first color */
  
  --_g: #0000 52%,var(--c) 54% 57%,#0000 59%;
  background: 
   radial-gradient(farthest-side at -33.33% 50%,var(--_g)) 0 calc(var(--s)/2),
   radial-gradient(farthest-side at 50% 133.33%,var(--_g)) calc(var(--s)/2) 0,
   radial-gradient(farthest-side at 133.33% 50%,var(--_g)),
   radial-gradient(farthest-side at 50% -33.33%,var(--_g)),
   #67917A; /* second color */
  background-size: calc(var(--s)/4.667) var(--s),var(--s) calc(var(--s)/4.667);
}
#AWSImage-Description{
    display: none;
}
@media screen and (max-width: 768px) {
    .experienceblock{
        display: block;
    }
    .left-aws{
        display: block;
        height: fit-content;
    }
    .right{
        width: 90%;
        height: 400px;
    }
    .text{
        text-align: center;
    }
    .subtitle{
        text-align: center;
    }
    #AWSImage-Description{
        display: none;
    }
    .left-aws p, .left-tlbu p{
        font-size: 0.8rem;
    }
}