.cards{
    width: 100%;
    height: auto;
    margin-bottom: 100px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
   border-radius: 30px;
   padding-bottom: 20px;
   overflow-x: scroll;

}
.topCardNav{
    width: 100%;
    height: 40px;
    background-color: darkslategrey;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}
.topCardNav p{
    margin: 5px;
    color: white;
    font-size: 1.2rem;
}
.active{
    font-weight: bolder;
    text-decoration: underline;
}
#courseDescription h1{
    font-size: 1rem;
}

#courseDescription li{
    font-size: 1rem;
}

#line{
    width: 100%;
    height: 1px;
    background-color: darkslategray;
    margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
    .topCardNav p{
        font-size: 0.8rem;
    }
    #courseDescription li{
        font-size: 0.7rem;
    }
}