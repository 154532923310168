

.cardblur{
width: 80%;
height: 50%;
display: flex;
justify-content: center;
align-items: center;
    background: rgba(255, 255, 255, 0.56);
    border-radius: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    backdrop-filter: blur(13.2px);
    -webkit-backdrop-filter: blur(13.2px);
    border: 1px solid rgba(255, 255, 255, 0.3);
        color: darkslategray;
}

.rectangle-container {
    position: relative;
    width: max(40vw, 400px);
    height: min(40vh, 400px);
    border: 2px solid white; /* #001800;*/
    margin: 50px; /* Optional margin for spacing */
  }
  
.letter-container{
    width: min(7vw, 9em);
    height: min(7vw, 9em);
    display: flex;
    align-items: center;
    justify-content: end;
    background-color: orange;
}
.letter{
    font-size: min(7vw, 9em);
}
.homeskill{
    /* background-color: antiquewhite; */
    text-align: center;
    /* background: #D5DAD6; */
    border-radius: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    height: 150px;
    overflow-y: scroll;
}
.homegrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates 4 columns of equal width */
    gap: 10px; /* Adjust the gap between grid items */
}
.homeskill p {
    font-size: 1rem;
}
.homeskill h3{
    margin-top: 10px;
    font-size: 18px;
    color: #40826D;
}

.rectangle-container div{
    color: white;
}
