.projects{
    width: inherit !important;  
    overflow: hidden;
}
.projects h5{
    color: darkslategray;
}
.RAGProject{
    margin: 5px;
    padding: 5px;
    margin-top: 30px;
    background-color: white;
    border-radius: 16px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.RAG-image {
    background-image: url('../artifact/1wrgK30VW2Y6sXK9mui-dqA.webp');
    background-repeat: no-repeat;
    background-size:contain; /* Ensures the image covers the entire element */
    background-position: center; /* Centers the image within the element */
    width: 100%;
    height: 400px;
}

.RAGProject .techstack {
    display: flex; /* Arrange items in a row */
    flex-wrap: wrap; /* Allow wrapping to the next line if necessary */
    gap: 10px; /* Space between items */
    justify-content: center; /* Center align the items */
    margin: 20px 0; /* Add some spacing above and below */
}

.RAGProject .techstack p {
    background-color: darkslategray/*#f9f9f9; */;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    padding: 10px 15px; 
    border-radius: 8px; 
    margin: 0; 
    font-size: 14px;
    /* color: #333;  */
    color: white;
    text-align: center; 
}
