.datascience{
    width: min(90vw, 1500px);
    height: auto;
    display: flex;
    /* justify-content: center; */
    overflow: hidden;
}
.datascienceContainer{
    width: 90vw;
    height: 60vh;
    /* background-color: #f4f6fb; */
    margin-bottom: 50px;
    border-radius: 30px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    display: flex;
    /* justify-content: center; */
    overscroll-behavior-x: none;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    /* scroll-behavior: smooth; */
}
.card p{
    font-size: 1rem;
}
.card-info{
    width: 100%;
}
.card-image{
    width: 100%;
    height: 50px;
    margin-top: 10px;
    margin-bottom: 20px;
    background-size: cover;
}
@media screen and (max-width: 768px) {
    /* .card-front{
        min-width: 300px;
    } */
}

.card {
   margin-right: 10px;
    min-width: 400px;
    height: 450px;
    perspective: 1000px;
    transform-style: preserve-3d;
    transition: transform 0.5s;
    /* background: #D5DAD6; */
    background-color: white;
    border-radius: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(83, 190, 255, 0.14); */
  }
  
  .card.flipped {
    transform: rotateY(180deg);
  }
  
  .card div {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  .card-front {
    transform: rotateY(0deg);
  }
  
  .card-back {
    transform: rotateY(180deg);
    /* overflow: auto; */
  }
  .card-info{
   margin: 5px;
  }
  .card button{
    /* background-color:lightsteelblue; */
    border-style: none;
    background-color: transparent;
  }